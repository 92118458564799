import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from "../Assets/images/logo.png";
import '../Assets/styles/home.css';
import ads from '../Assets/images/ZRU-scholarship-banner-2024.jpeg';
import { rudderanalytics } from '../utils/RudderStack';
// import * as rudderanalytics from "rudder-sdk-js";

const HomePage = () => {
  
  const navigate = useNavigate();
  const [enrollmentno, setEnrollmentNumber] = useState('');
  const [error, setError] = useState('');
  const [surname, setSurname] = useState('');
  const [open, setOpen] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [authenticated, setAuthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));



  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const data = {
      enrollmentno: enrollmentno,
      surname: surname,
    };

    const url = 'https://api.early.school/api/onlineexam/login';
    // const url = 'http://localhost:8000/api/onlineexam/login';
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if (!response.ok) {
        setLoginSuccess(false);
        setOpen(true);
      }
      return response.json();
    })
    .then((data) => {
      console.log(data);
      if (data.status === 'success') {
        setLoginSuccess(true);
        setOpen(true);
        setAuthenticated(true);
        // sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('student', JSON.stringify(data.student));
        sessionStorage.setItem('profile', JSON.stringify(data.profile));
        sessionStorage.setItem('school', JSON.stringify(data.school));
        sessionStorage.setItem('myclass', JSON.stringify(data.myclass));
        sessionStorage.setItem('currentsession', JSON.stringify(data.currentsession));
        sessionStorage.setItem('currentclass', JSON.stringify(data.currentclass));
        sessionStorage.setItem('currentsection', JSON.stringify(data.currentsection));
        // alert("login was successful");
        navigate("/dashboard");
        return;
      } else {
        setError(data);
        setLoginSuccess(false);
        setOpen(true);
        setError(data);
      }
    });
  };

  return (
    <div>   
      <section>
      <div className='all'>
        <form onSubmit={handleSubmit}>
          <div className='header'>
            <img src={logo} width={150} style={{backgroundColor:'white', borderRadius:'10%'}} />
            
            <h1 className="cbt mt-3">ONLINE EXAM</h1>
            <h4 className="cbt">LOG IN</h4><br/>
            { error !== '' &&
              (
                <div className='alert alert-danger'>
                  <span className="text">{error.message}</span>
                </div>
              )
            }
              
          </div>
          <div className='username'>
            <div className='inputbox'>
              <label className="label1" htmlFor="enrollmentno">ENROLLMENT NUMBER</label><br/>
              <input
                type="Text"
                id='enrollmentno'
                value={enrollmentno}
                onChange={(event) => setEnrollmentNumber(event.target.value)}
                placeholder="Enrollment Number"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className='password'>
            <div className='inputbox'>
             <label className="label1 text-light" htmlFor="surname">SURNAME</label> <br/>
             <input
                type="Text"
                id='surname'
                value={surname}
                onChange={(event) => setSurname(event.target.value)}
                placeholder="Surname"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className='my-3'>
            <button className='btn text-light border-light' type="submit">LOG IN</button>
          </div>
        </form>
      </div>
      </section>
      <center>
        <img src={ads} height='50px' style={{position:'relative', bottom:'0px', cursor: 'pointer'}} onClick={() => window.open('https://www.zru.ng/', '_blank')} />
      </center>
    </div>
  );
}

export default HomePage;
