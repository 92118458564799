import React from 'react';
import { Link } from 'react-router-dom';

const DescriptionPage = () => {
  return (
    <div >

      <div  className='jumbotron bg-secondary '>
      <div class="container-fluid bg-secondary">

<div class="header">
    <h2> ENGLISH LANGUAGE</h2>
    <h4>READ THE BELOW GUIDELINES CAREFULLY BEFORE PROCEEDING TO YOUR EXAM</h4>
</div>
<main>
    <p>1. Press N for Next</p>
    <p>2. Press P for Previous</p>
    <p>3. Press A for Option A</p>
    <p>4. Press B for Option B</p>
    <p>5. Press C for Option C</p>
    <p>6. Press D for Option D</p>
    <p>7. Press S for Submit</p>
    <p>8. Press Y for Confirm</p>
    <center>
    <Link to="/exam">
      <button className='btn btn-primary mb-5 text' onclick="description()"> START EXAM </button>
    </Link>
    </center>
</main>

</div>
      </div>
    </div>
  );
}

export default DescriptionPage;
