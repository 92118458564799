import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AllSubjectPage from './pages/AllSubjectPage';
import DescriptionPage from './pages/DescriptionPage';
import ExamPage from './pages/ExamPage';
import ScorePage from './pages/ScorePage';
import StartPage from './pages/StartPage';
import Logout from './pages/Logout';
// ... other imports

function App() {
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/dashboard" element={<AllSubjectPage />} />
        <Route path="/description" element={<DescriptionPage />} />
        <Route path="/:exam_id" element={<StartPage />} />
        <Route path="/:exam_id/exam" element={<ExamPage />} />
        <Route path='/score' element={<ScorePage />} />
        <Route path='/logout' element={<Logout />} />
        {/* Add other routes */}
      </Routes>
    </Router>
  );
}

export default App;
