import React from "react";
import { Link } from "react-router-dom";


const ScorePage = () => {
    return (
        <div>
            <div class="nav1">
        <p class="bi bi-person-fill ">   <span> USERNAME</span></p>
        <button class="score"><i class="bi bi-check-circle-fill"></i>&nbsp;Submit</button>
    </div>
    <div class="nav1">
        <button class="rest">Result</button>
        <Link to="/home">
        <button class="logout"> <i class="bi bi-box-arrow-left arrow"></i>&nbsp;LOG OUT</button>
        </Link>
    </div>

    <br/>
    <div class="drop">
        <details>
            <summary>SUMMARY</summary>
            <p>
            <table class="tableup" border="3">
                <tr>
                    <td class="data">Total score</td>
                    <td>60/100(60%)</td>
                </tr>
                <tr>
                    <td class="data">Total Time Spent</td>
                    <td>1hr 27min 45secs/2hrs 0 sec (70%)</td>
                </tr>
                <tr>
                    <td class="data">Average Speed <i class="bi bi-patch-question-fill"></i></td>
                    <td>45m/s</td>
                </tr>
            </table>
        </p>
        </details>
        
        <br/>

        <details>
            <summary>DETAIL</summary>
            <p>
            <table class="tabledown" >
               <h3 class="abl">Examination details</h3>
                <tr>
                    <td class="dat">Username:</td>
                    <td> Username</td>
                </tr>
                <tr>
                    <td class="dat">Subject:</td>
                    <td>English Language</td>
                </tr>

                <tr>
                    <td class="dat">Term:</td>
                    <td>First Terminal Examination</td>
                </tr>
                <tr>
                    <td class="dat">Session:</td>
                    <td>2023/2024</td>
                </tr>
                <tr>
                    <td class="dat">Exam date/Time:</td>
                    <td>10/7/2023, 2:01 PM</td>
                </tr>
                <tr>
                    <td class="dat">Total Questions:</td>
                    <td>100</td>
                </tr>
                <tr>
                    <td class="dat">Centre Name:</td>
                    <td>Futuristic Code Academy</td>
                </tr>
            </table>
        </p>

        

        

        <table class="tabledown" >
            <h3 class="abl">Candidate Result</h3>
            <tr class="ta">
                <td>Total Question</td>
                <td>100</td>
                <td>Time Spent</td>
            </tr>
             <tr>
                <td class="dat">Question Answered</td>
                <td> 60</td>
                <td>55 secs</td> 
             </tr>
             <tr>
                <td class="dat">Question Missed</td>
                <td> 40</td>
                <td>55 secs</td>
             </tr>
             <tr>
                <td class="dat dat1">Aggregrate</td>
                <td>60/100</td>
                <td>Total: 1mins 50secs</td>
             </tr>
         </table>
        
        </details>
    </div>
    <br/><br/>
        </div>
    )
}






export default ScorePage;