import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const StartPage = () => {
  const { exam_id } = useParams();
  const id = Number(exam_id);
  const [exam, setExam] = useState(null); // Initialize as null or with a default object
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`https://api.early.school/api/onlineexam/listexam/` + id)
      // .get(`http://localhost:8000/api/onlineexam/listexam/` + id)
      .then((response) => {
        const data = response.data;
        if (data.status === 'success') {
          console.log(data);
          setExam(data.exam);
        } else {
          alert('Verification failed');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('An error occurred during verification');
      });
  }, [id]);
  
  return (
    <div className='container-md'>
      <div className='jumbotron' style={{height: '100vh', display: 'flex', alignItems: 'center'}}>
        <div className='container-fluid bg-secondary rounded'>
          <Link to='/dashboard' className='text-decoration-none text-light fs-1'>&larr;</Link>
          <div className='header'>
            <h1>{exam !== null ? exam.exam_settings.exam_title : null}</h1>
          </div>
          <main>
            <p>{exam !== null ? exam.exam_settings.exam_instructions : ''}</p>
            <p>Total Questions: {exam !== null ? exam.exam_settings.exam_questions_per_student : ''}</p>
            <center>
              <Link to={"/" + id + "/exam"}>
                <button className='btn btn-primary mb-5 text'> START EXAM </button>
              </Link>
            </center>
          </main>
        </div>
      </div>
    </div>
  );
}

export default StartPage;