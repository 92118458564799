import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../Assets/styles/examlisting.css";
import ads from '../Assets/images/ZRU-scholarship-banner-2024.jpeg';

const AllSubjectPage = () => {
    const [exams, setExams] = useState([]);
    const navigate = useNavigate();
    const student = JSON.parse(sessionStorage.getItem('student'));
    const myclass = JSON.parse(sessionStorage.getItem('myclass'));
    useEffect(() => {
          axios
            .post(`https://api.early.school/api/onlineexam/listexam/student`, {
            // .post(`http://localhost:8000/api/onlineexam/listexam/student`, {
                school_id: myclass.school_id,
                session_id: myclass.session_id,
                class_id: myclass.class_id,
                section_id: myclass.section_id
            })
            .then((response) => {
                // console.log(response.data);
              const data = response.data;
              if (data.status === 'success') {
                setExams(data.exams)
              } else {
              }
            })
            .catch((error) => {
              console.error('Error:', error);
              alert('Error finding exams, try logging out');
            });
        // }
    }, []);

    const examType = (examtype) => {
        switch (examtype){
            case 1: return 'First CA';
            case 2: return "Second CA";
            case 3: return "Third CA";
            case 4: return "Exam";
            default: return ""
        }
    }

    const handleLogout = () => {
        navigate('/logout');
    }

    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-10 col-md-10 col-lg-10'><h2 className='text-center text-primary my-3'>List of available exam</h2></div>
                    <div className='col-sm-2 col-md-2 col-lg-2'><button onClick={handleLogout} className='btn btn-responsive btn-danger my-3'>Logout</button></div>
                </div>
                <div className='table-responsive'>
                <table className="table mb-0 table-striped">


                    <thead className=''>
                        <tr>
                            <td>Exam</td>
                            <td>Subject</td>
                            <td>For</td>
                            <td>Deadline</td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                    { !exams ?
                        <tr>No exam found</tr>
                        : 
                        (     
                            exams.map(ex => (
                                <tr className="align-middle" key={ex.id}>
                                    <td>
                                        <div className="h6 mb-0 lh-1">{ex?.exam_settings?.exam_title}</div>
                                    </td>
                                    <td>{ex?.subject_name}</td>
                                    <td className="text-justify">{ examType(ex?.exam_settings?.exam_type)}</td>
                                    <td>{ex?.exam_settings?.exam_close_date_time}</td>
                                    <td><Link to={'/'+ex?.id} className='btn btn-primary text-white' >Open</Link></td>
                                </tr>
                            ))
                        )
                    }
                    </tbody>
                </table>
                </div>
                <center>
                    <img src={ads} height='50px' style={{position:'relative', bottom:'0px', cursor: 'pointer'}} onClick={() => window.open('https://www.zru.ng/', '_blank')} />
                </center>
            </div>
        </div>
    );
}

export default AllSubjectPage;
