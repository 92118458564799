import {useEffect} from "react";
import { useNavigate } from "react-router-dom";


const Logout = () => {
    const navigate = useNavigate();
    useEffect(() => {
        sessionStorage.removeItem('student');
        sessionStorage.removeItem('profile');
        sessionStorage.removeItem('school');
        sessionStorage.removeItem('myclass');
        sessionStorage.removeItem('currentsession');
        sessionStorage.removeItem('currentclass');
        sessionStorage.removeItem('currentsection');
        navigate("/");
    })

    return (
        <div></div>
    );
}

export default Logout;