 import React, { useEffect, useState } from 'react';
import "../Assets/bootstrap/bootstrap.min.css";
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ExamPage = () => {
  const navigate = useNavigate();
  if (!sessionStorage.student) {
    navigate("/");
  } else {
    
  }
  const student = JSON.parse(sessionStorage.getItem('student'));
  const profile = JSON.parse(sessionStorage.getItem('profile'));
  const myclass = JSON.parse(sessionStorage.getItem('myclass'));
  
  const school_id = myclass?.school_id;
  const student_id = myclass?.student_id;
  const session_id = myclass?.session_id;
  const class_id = myclass?.class_id;
  const section_id = myclass?.section_id;

  const space = {
    paddingLeft: '4%'
  }

  const space1 = {
    paddingLeft: '5%'
  }

  const { exam_id } = useParams();
  const id = Number(exam_id);
  const [exam, setExam] = useState(null); 
  const [questions, setQuestions] = useState([]);
  const [examresult, setExamResult] = useState([]);
  const [eligible, setEligible] = useState('loading');

  // Initialize a state variable to store the user's answers
  const [userAnswers, setUserAnswers] = useState({});

  function arraysAreEqualRegardlessOfOrder(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    }
  
    const sortedArray1 = [...array1].sort();
    const sortedArray2 = [...array2].sort();
  
    for (let i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) {
        return false;
      }
    }

    return true;
  }
  
  function arraysAreEqualInOrder(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    }
  
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
  
    return true;
  }  

  function formatResultData(userAnswers, correctAnswers) {
    const formattedData = [];
  
    for (const questionId in userAnswers) {
      if (userAnswers.hasOwnProperty(questionId)) {
        const submittedAnswer = userAnswers[questionId];
        const correctAnswer = correctAnswers[questionId];
        formattedData.push({
          question_id: parseInt(questionId, 10),
          submitted_answer: submittedAnswer,
          correct_answer: correctAnswer,
        });
      }
    }
  
    return formattedData;
  }
  
  // Function to handle form submission
  function handleSubmit(event) {
    event.preventDefault();
  
    // Iterate through questions and check selected options against correct answers
    const userScore = questions.reduce((totalScore, question) => {
      const questionId = question.id;
  
      const correctAnswers = {};
      correctAnswers[question.id] = question.question_settings.question_correct_answer || [];
  
      if (question.question_settings.question_type === 'multi_select_multiple_choice') {
        // For multi-select multiple-choice questions
        const userSelectedOptions = userAnswers[questionId] || [];
        const correctOptions = correctAnswers[questionId] || [];
        const isCorrect = arraysAreEqualRegardlessOfOrder(userSelectedOptions, correctOptions);
        // Increase the score if the user's answer is correct
        if (isCorrect) {
          return totalScore + question.question_settings.question_points;
        }
      } else if (question.question_settings.question_type === 'single_multiple_choice') {
        // For multi-select single-choice questions
        userAnswers[questionId] = userAnswers[questionId] + 1
        const userSelectedOption = userAnswers[questionId];
        const correctOption = correctAnswers[questionId]; // Parse as an integer
        // Increase the score if the user's answer is correct
        if (userSelectedOption === correctOption) {
          return totalScore + question.question_settings.question_points;
        }
      } else if (question.question_settings.question_type === 'true_false') {
        // For true/false questions
        const userSelectedOption = userAnswers[questionId];
        const correctOption = correctAnswers[questionId];
        // Increase the score if the user's answer is correct
        if (userSelectedOption === correctOption) {
          return totalScore + question.question_settings.question_points;
        }
      } else if (question.question_settings.question_type === 'fill_in_the_blanks') {
        // For fill_in_the_blanks questions
        if (userAnswers[questionId] == null){
          // console.log("I am null");
          return totalScore
        } else {
          const correctOption = correctAnswers[questionId];
          // console.log(correctAnswers[questionId]);
          const userSelectedString = Object.values(userAnswers[questionId]);
          // console.log(userSelectedString);

          const isCorrect = arraysAreEqualRegardlessOfOrder(userSelectedString, correctOption);
          if (isCorrect) {
            return totalScore + question.question_settings.question_points;
          }
          return totalScore
        }
      }
      return totalScore;     
    }, 0);
  
    // You can display the user's score or perform any other actions
    // console.log('User Score:', userScore);
  
    // Create the correctAnswers object for formatting
    const correctAnswers = {};
    questions.forEach((question) => {
      correctAnswers[question.id] = question.question_settings.question_correct_answer || [];
    });
  
    // Format and stringify the result data
    const formattedData = formatResultData(userAnswers, correctAnswers);
    const formattedDataJSON = JSON.stringify(formattedData);
  
    // console.log(formattedDataJSON);
    axios
      .post(`https://api.early.school/api/onlineexam/${id}/submit`, {
      // .post(`http://localhost:8000/api/onlineexam/${id}/submit`, {
        result_id: examresult.id,
        student_id: student_id,
        school_id: school_id,
        student_result: userScore,
        exam_answer_script: formattedDataJSON,
        exam_status: 'finished',
        exam_end_date_time: null,
      })
      .then((response) => {
        const data = response.data;
        console.log(data);
        if (data.status === 'success') {
          // console.log(data);
          setExamResult(data.result);
          setTimeout(() => { 
            navigate('/dashboard')
          }, 10000);
        } else {
          console.log('Error occured while submitting exam!');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });

      // Add code to send formattedDataJSON to the database
  }
  
  function handleAnswerSelection(questionId, optionKey, questionType, isChecked) {
    setUserAnswers((prevAnswers) => {
      // Create a copy of the previous answers state
      const updatedAnswers = { ...prevAnswers };
      if (questionType === 'true_false') {
        updatedAnswers[questionId] = optionKey;
      } else if (questionType === 'multi_select_multiple_choice') {
        optionKey = parseInt(optionKey)
        if (!updatedAnswers[questionId]) {
          updatedAnswers[questionId] = [];
        }
  
        if (isChecked) {
          updatedAnswers[questionId].push((optionKey+1).toString());
        } else {
          updatedAnswers[questionId] = updatedAnswers[questionId].filter((item) => item !== (optionKey+1).toString());
        }
  
        // Make the updatedAnswers[questionId] array unique
        updatedAnswers[questionId] = Array.from(new Set(updatedAnswers[questionId]));
      } else if (questionType === 'single_multiple_choice') {
        // For single-choice questions, store only one selected option as a number
        updatedAnswers[questionId] = parseInt(optionKey, 10);
      } else if (questionType === 'fill_in_the_blanks') {
        let value = isChecked;
        updatedAnswers[questionId] = { ...updatedAnswers[questionId], [optionKey]: value };
      }

      return updatedAnswers;
    });
  }

  useEffect(() => {
    axios
        .post(`https://api.early.school/api/onlineexam/${id}/start`, {
        // .post(`http://localhost:8000/api/onlineexam/${id}/start`, {
        student_id: student_id,
        school_id: school_id,
        student_result: null,
        exam_status: 'started',
        exam_end_start_time: null,
        exam_end_date_time: null,
      })
      .then((response) => {
        const data = response.data;
        // console.log(data);
        if (data.status === 'success') {
          setExamResult(data.result);
          console.log(data.result);
        } else {
          console.log('Error occured while trying start exam!');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [id]);

  // gets exam details
  useEffect(() => {
    axios
      .get(`https://api.early.school/api/onlineexam/listexam/` + id)
      // .get(`http://localhost:8000/api/onlineexam/listexam/` + id)
      .then((response) => {
        const data = response.data;
        if (data.status === 'success') {
          // console.log(data);
          setExam(data.exam);
          if ((data.exam.class_id == class_id) && (data.exam.session_id == session_id) && (data.exam.section_id == section_id))
            setEligible('continue');
          else setEligible('invalid')
        } else {
          console.log("error ocurred while fetching exam")
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        console.log('An error occurred during verification');
      });
  }, [id]);

  // gets all exam questions
  useEffect(() => {
    axios
      .get(`https://api.early.school/api/onlineexam/${id}/questions`)
      // .get(`http://localhost:8000/api/onlineexam/${id}/questions`)
      .then((response) => {
        const data = response.data;
        if (data.status === 'success') {
          console.log(data);
          setQuestions(data.questions);
        } else {
          console.log('Error occured while trying to get questions');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        console.log('An error occurred during question fetching');
      });
  }, [id]);

  // ... (Other imports and code)

  return (
    <div className='container-md'>
      <nav>
      
            <p className="bi bi-person-fill cont text-center p-2" style={{fontSize:'30px',  fontWeight:'600',}}> <span id="user"> { profile?.first_name+' '+profile?.last_name }</span></p>
            <center>
              <h1>{exam?.exam_settings?.exam_title}</h1>
            </center>
            <div className='container-fluid d-flex justify-content-between p-2'>
            <h4>Subject: <span>{exam?.subject_name}</span></h4>  
              <h4>Class: <span>{exam?.class_name}</span></h4>
            </div>

          <div className='container-fluid'>
            <button className="btn score w-100 btn-primary" data-bs-toggle="modal" data-bs-target="#modal2">Instruction</button>
            
          </div>
            
            <div className=  "modal" id="modal2">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1>Instruction</h1>
                            <button className="btn-close" data-bs-dismiss="modal"  ></button>
                            
                        </div>
                        <div className="modal-body">
                            <p>{exam?.exam_settings?.exam_instructions}</p>
                        </div>
                        <div className="modal-footer">
                            <p>GOOD LUCK</p>
                        </div>
                    </div>
                </div>
            </div>
         
      </nav>
      
      {eligible === 'loading' && <><p>Loading</p></>}
      {eligible === 'invalid' && <p className='text-center text-danger my-5'>Oops! you do not have access to this exam</p>}
      {eligible === 'continue' &&
        <form onSubmit={handleSubmit}>
          <div className='px-4 my-2'>
            
            <br /><br />

            {questions.length > 0 ? (
              questions.slice(0, exam?.exam_settings?.exam_questions_per_student || questions.length).map((question, index) => (
              
              <div key={question.id} className='' style={{}}>
                <h4>
                  <label className="lab" htmlFor={`question-${question.id}`}>{index + 1}. {question.question_settings.question_title}</label>
                </h4>
                {question?.question_settings?.question_type === 'multi_select_multiple_choice' && (
                  <div className="all-label"  style={{}}>
                    {Object.entries(question?.question_settings?.question_options).map(([optionKey, optionValue]) => (
                      <label key={optionKey} className="la form-check-label mx-3 fs-4" htmlFor={`question-${question.id}-${optionKey}`}>
                        <input type="checkbox" className='form-check-input' name={`question-${question.id}-${optionKey}`} id={`question-${question.id}-${optionKey}`} value={optionKey} 
                          onChange={(e) => handleAnswerSelection(question.id, optionKey, 'multi_select_multiple_choice', e.target.checked)} />
                        <span className='ms-2'>{optionValue}</span>
                      </label>
                    ))}
                  </div>
                )}
                {question?.question_settings?.question_type === 'single_multiple_choice' && (
                  <div className="all-label"  style={{}}>
                    {question?.question_settings?.question_options != null && Object.entries(question?.question_settings?.question_options).map(([optionKey, optionValue]) => (
                      <label key={optionKey} className="la form-check-label mx-3 fs-4" htmlFor={`question-${question.id}-${optionKey}`}>
                        <input type="radio" className='form-check-input' name={`question-${question.id}`} id={`question-${question.id}-${optionKey}`} 
                        onChange={() => handleAnswerSelection(question.id, optionKey, 'single_multiple_choice', null)} />
                        <span className='ms-2'>{optionValue}</span>
                      </label>
                    ))}
                  </div>
                )}
                {question?.question_settings?.question_type === 'true_false' && (
                  <div className="all-label" style={{}}>
                    <label className='form-check-label mx-3 fs-4'>
                      <input
                        type="radio"
                        name={`question-${question.id}`}
                        id={`question-${question.id}-true`}
                        value="true"
                        className='form-check-input'
                        onChange={() => handleAnswerSelection(question.id, 'true', 'true_false', null)}
                      />
                      True
                    </label>
                    <label className='form-check-label mx-3 fs-4'>
                      <input
                        type="radio"
                        name={`question-${question.id}`}
                        id={`question-${question.id}-false`}
                        value="false"
                        className='form-check-input'
                        onChange={() => handleAnswerSelection(question.id, 'false', 'true_false', null)} 
                      />
                      False
                    </label>
                  </div>
                )}
                {question?.question_settings?.question_type === 'fill_in_the_blanks' && (
                  <div className="all-label" style={{}}>
                    {question?.question_settings?.question_correct_answer.map((_, index) => (
                      <div key={index} className="form-group mx-3 fs-4">
                        <label htmlFor={`question-${question.id}-${index}`}>{index + 1}. Fill in the blank:</label>
                        <input
                          type="text"
                          className="form-control"
                          id={`question-${question.id}-${index}`}
                          onChange={(e) => handleAnswerSelection(question.id, index, 'fill_in_the_blanks', e.target.value)}
                        />
                      </div>
                    ))}
                  </div>
                )}
  

                <br /><br />
              </div>
            ))
            ) : (
              <p className='text-center text-danger'>No question found. Please, contact your school administrator.</p>
            )}

            <button className="btn score btn-outline-danger w-100 m-auto" data-bs-toggle="modal" data-bs-target="#modal" type="submit">Submit</button>
            {/* <button type="submit">Submit</button> */}
          </div>
        </form>
      }
      <div className="modal" id="modal">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content text-center">
                <div className="modal-header">
                    <h1>Instruction</h1>
                    <button className="btn-close" data-bs-dismiss="modal"></button>
                    
                </div>
                <div className="modal-body">
                    <h2>Your score in {exam?.exam_settings?.exam_title} is </h2>
                    <h1>{ JSON.stringify(examresult?.student_result) }</h1>
                </div>
                <div className="modal-footer">
                    <p>FINISH</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default ExamPage;